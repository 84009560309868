import React from 'react';
import { connect } from "react-redux";
import { Collapse, Card, Panel, Table, Select, Form, Alert, Divider } from 'antd';
import Filter from "../Filter/filter.js"
import { fetchBolleDetail } from '../../actions/actions.js';
import get from "lodash.get";
import { Input, Button, Modal, InputNumber } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { moneyFormat } from "../../utils.js"
import { getApiUrl } from '../../utils.js'

import "./style.css"
import TextArea from 'antd/lib/input/TextArea';

const mapStateToProps = state => {
  return {
    bolleDetail: state.bolleDetail,
    user: state.user
  };
};
function mapDispatchToProps(dispatch) {

  return {
    fetchBolleDetail: (last, filter) => dispatch(fetchBolleDetail(last, filter)),
  }
}

class NuovoReso extends React.Component {

  constructor(props) {
    super(props);

    /*
     * LOCAL STATE
    */
    this.state = {
      bolleDetail: this.props.bolleDetail,
      filter: {},
      key: 1,
      pageSize: 10,
      notePlaceholder: null,
      rrId: false,
      showPopup: false,
      popupRecord: null,
      popupKey: 1,

      returnReqType: [],

      showSuccessMessages: false
    }

    this.refQnt = React.createRef();
    this.refNote = React.createRef();
    this.refReqType = React.createRef();
    this.refForm = React.createRef();
    this.onSubmitFilter = this.onSubmitFilter.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.askReturn = this.askReturn.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onChangeReqType = this.onChangeReqType.bind(this);

  }

  onSubmitFilter(filter) {

    this.setState({
      ...this.state,
      filter: filter
    }, function () {
      console.log(filter)
      this.props.fetchBolleDetail(null, this.state.filter)
    })
  }

  componentDidMount() {
    fetch(getApiUrl() + "/returns/getReturnsReason", {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
      headers: new Headers({
        "Accept": 'application/json'
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw (res);
        } else {

          this.setState({
            ...this.state,
            returnReqType: res.data,
          })

        }
      })
      .catch(res => {
      })

    //Load last documents
    //this.props.fetchBolleDetail(null, this.state.filter);

  };



  /* Manage state update */
  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.bolleDetail !== prevProps.bolleDetail) {
      this.setState({
        ...this.state,
        bolleDetail: this.props.bolleDetail
      })
    }
  }

  getColumnSearchProps = (dataIndex, exact, dataIndex2, replaceDotWithComma) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Elimina i filtri
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
    onFilter: function (value, record) {

      console.log("On filter called with value: " + value + " and record")
      console.log(record)

      if (replaceDotWithComma) {
        value = value.replaceAll(',', '.')
      }


      //Search on field1 + field2  
      if (dataIndex2 !== null && dataIndex2 !== undefined) {
        if (exact == null || exact === undefined || exact === false) {
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase().includes(value.toString().toLowerCase())
        } else {
          //exact
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase() === (value.toString().toLowerCase())
        }

      }
      else if (exact === null || exact === undefined || exact === false) {
        console.log("Search exact false ")
        console.log(("Record:" + get(record, dataIndex) || "").toString().toLowerCase())
        console.log("Value:" + value.toString().toLowerCase())
        return (get(record, dataIndex) || "").toString().toLowerCase().includes(value.toString().toLowerCase())
      }
      else {
        return (get(record, dataIndex) || "").toString().toLowerCase() === value.toString().toLowerCase()
      }
    }
    ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });


  handleReset() {
    this.setState({
      ...this.state,
      key: this.state.key + 1
    })
  };

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }


  showPopup(record) {
    this.setState({
      ...this.state,
      showPopup: true,
      popupRecord: record,
      rrId: false
    })
  }

  hideModal() {
    this.setState({
      ...this.state,
      showPopup: false,
      popupRecord: [],
      popupKey: this.state.popupKey + 1,
      showSuccessMessages: false
    })
  }

  askReturn() {
    console.log("refReqType before validation: " + this.refReqType.current.props.value)

    this.refForm.current.validateFields()
      .then(v => {
        //SUCCESS VALIDATION
        console.log("refReqType after validation: " + this.refReqType.current.props.value)
        let par = {
          rPrecode: this.state.popupRecord.precodice,
          rCode: this.state.popupRecord.articolo,
          rQnt: this.refQnt.current.props.value,
          rOutboundDdtType: this.state.popupRecord.td_bolla,
          rOutboundDdt: this.state.popupRecord.num_bolla,
          rOutboundDdtDate: this.state.popupRecord.data_bolla,
          rOutboundDdtRow: this.state.popupRecord.id_riga,
          rNote: this.refNote.current.props.value,
          rrId: this.refReqType.current.props.value,

        }



        fetch(getApiUrl() + "/returns/add", {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'include',
          headers: new Headers({
            "Content-Type": 'application/json',
            "Accept": 'application/json'
          }),
          body: JSON.stringify(par)
        })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              throw (res);
            } else {
              this.setState({
                ...this.state,
                showSuccessMessages: true,

              })

            }
          })
          .catch(res => {
            alert(res.message)
          })

      })

      //ERROR IN VALIDATION
      .catch(errorInfo => console.log("validation error"))


  }

  onChangeReqType(e) {
    if (e === '4') { //se selezionato Difettoso o non conforme

      this.setState({
        ...this.state,
        rrId: true,
        notePlaceholder: "Inserire obbligatoriamente la TARGA del veicolo per procedere con la richiesta."
      })

    } else {

      if (this.state.rrId === true) {

        console.log("RRID: " + this.refForm.current.getFieldValue('note'))
        this.refForm.current.setFieldsValue({ note: '' })

      }

      this.setState({
        ...this.state,
        rrId: false,
        notePlaceholder: null
      })
    }
  }



  render() {

    let maxQntaReq = 1
    if (this.state.popupRecord !== null && this.state.popupRecord.quantita !== undefined) {
      maxQntaReq = this.state.popupRecord.quantita
    }

    const { Panel } = Collapse;
    let that = this;

    const columns = [

      {
        title: 'Bolla', dataIndex: 'Bolla', key: 'bolla', width: "10%", ...this.getColumnSearchProps(["td_bolla"], false, ["num_bolla"]),
        render: function (text, record, index) {
          return (<span>{record.td_bolla}-{record.num_bolla}</span>)
        }
      },
      {
        title: 'Data Bolla', dataIndex: 'data_bolla', key: 'data_bolla', width: "15%", ...this.getColumnSearchProps('data_bolla'),
        render: function (text, record, index) {
          return (<span>{(record.data_bolla || "").substring(0, 10)}</span>);
        }
      },

      { title: 'Precodice', dataIndex: 'precodice', key: 'precodice', width: "7%", ...this.getColumnSearchProps('precodice', true) },
      { title: 'Articolo', dataIndex: 'articolo', key: 'articolo', width: "13%", ...this.getColumnSearchProps('articolo', true) },
      { title: 'Descrizione', dataIndex: 'descr_articolo', key: 'descr_articolo', width: "15%", ...this.getColumnSearchProps('descr_articolo', false) },
      { title: 'Qnt.', dataIndex: 'quantita', key: 'quantita', width: "7%", ...this.getColumnSearchProps('quantita', true) },

      {
        title: 'List.Un.', dataIndex: 'vu_lordo', key: 'vu_lordo', width: "7%", ...this.getColumnSearchProps('vu_lordo', true),
        render: function (text, record, index) {
          return (<span>{moneyFormat(record.vu_lordo)}</span>);
        },
      },
      {
        title: 'Netto Un.', dataIndex: 'vu_netto', key: 'vu_netto', width: "7%", ...this.getColumnSearchProps('vu_netto', true),
        render: function (text, record, index) {
          return (<span>{moneyFormat(record.vu_netto)}</span>);
        },
      },
    ];

    const colAction = {
      title: 'Azioni',
      render: function (text, record, index) {
        return (<Button onClick={e => { that.showPopup(record) }} type="primary">Chiedi Reso</Button>);
      },
    }

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const val = 0;

    return (
      <div>

        <p>Per chiedere un reso, cercare gli articoli da rendere nelle bolle e cliccare il pulsante "Chiedi Reso" </p>
        <Divider plain>
          <Button type="primary" onClick={this.onSubmitFilter}>Ricerca Tutto</Button>
        </Divider>
        <Collapse onChange={this.toggleCollapseText} >
          <Panel header="Ricerca Estesa" key="1">
            <Filter callback={this.onSubmitFilter} />
          </Panel>
        </Collapse>

        <div>
        <Divider/>
          {this.state.bolleDetail.requstIsPending === true && (
            <span>Ricerca in corso...</span>
          )}
        </div>
        <div> {this.state.bolleDetail.requstIsPending === false && (

          <Table key={this.state.key} className="search-results-table"
            columns={[...columns, colAction]} dataSource={this.state.bolleDetail.data}
            pagination={{
              pageSize: this.state.pageSize, showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange,
              pageSizeOptions: [5, 10, 15, 30, 50]
            }} locale={{ emptyText: 'Nessun documento da mostrare: Scegliere "Ricerca Tutto" oppure impostare i criteri della "Ricerca Estesa" e cliccare sul pulsante "Applica i Filtri" ' }}
          />
        )}</div>
        <Modal key={this.state.popupKey} width={"85%"} title="Conferma Reso" visible={this.state.showPopup} onOk={null} onCancel={this.hideModal}
          okText={null} cancelText="Annulla" okButtonProps={{ style: { display: 'none' } }} >
          <p>Stai chiedende il reso per l'articolo:</p>

          <Table key="popup" className="search-results-table"
            columns={columns} dataSource={[this.state.popupRecord]} />


          <Form ref={this.refForm} {...layout} name="basic" initialValues={{ remember: true }}  >

            <Form.Item label="Indicare la motivazione del reso" name="motivation" rules={[{ required: true, message: 'Inserisci la motivazione del reso' }]}>
              <Select ref={this.refReqType} onChange={this.onChangeReqType} >
                {this.state.returnReqType.map(function (el) {
                  console.log(el.rrId);
                  return <span key={el.rrId}>{el.rrExtendedText}</span>;
                })
                }
              </Select>
            </Form.Item>

            <Form.Item label="Indicare la quantità" name="qnt" rules={[{ required: true, message: 'Inserisci la quantita' }]}>
              <InputNumber ref={this.refQnt} min={1} max={maxQntaReq} defaultValue={1} />
            </Form.Item>

            <Form.Item  label="Note" name="note" rules={[{ required: this.state.rrId, message: 'Specificare la targa e il motivo', whitespace: true }]} >

              <TextArea placeholder= {this.state.notePlaceholder} ref={this.refNote} />

            </Form.Item>

            <Form.Item label="Cliccando sul pulsante affianco verrà richiesto il reso" name="qnt" >
              <Button onClick={this.askReturn} type="primary">Chiedi Reso</Button>
            </Form.Item>

          </Form>

          {this.state.showSuccessMessages === true && (
            <Alert message="Richiesta effettuata con successo" description="La tua richiesta di reso è stata completata con successo. 
                Puoi seguire la tua richiesta di reso tramite il menu 'Richiesto di Reso'." type="success" showIcon />
          )
          }

        </Modal>


      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NuovoReso);